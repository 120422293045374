import { Box, Divider, Icon, Typography } from '@mui/material'
import {
  admissionIcon,
  applicationIcon,
  loginIcon,
  registerIcon,
  stepFill,
  stepLogin,
  stepNotification,
  stepRegister,
  stepSubmit,
  stepVerify,
  submitIcon,
} from 'assets/images'
import React from 'react'
import { StyledStep, StyledStepBox, StyledStepBoxFrame } from './styles'
import { Variants, motion } from 'framer-motion'

const steps = [
  {
    label: 'Register yourself',
    icon: registerIcon,
  },
  {
    label: 'Login to your account',
    icon: loginIcon,
  },
  {
    label: 'Select Exam and Time Slot',
    icon: admissionIcon,
  },
  {
    label: 'Complete the Payment',
    icon: applicationIcon,
  },
  {
    label: 'Submit the Application',
    icon: submitIcon,
  },
]

function StepstoFollow() {
  return (
    <StyledStepBoxFrame>
      <Typography variant="h6" textAlign={'center'} sx={{ fontSize: '32px' }}>
        Application Process
      </Typography>
      <StyledStep>
        {steps.map((step, index) => (
          <StyledStepBox key={index}>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.2 }}
            >
              <motion.div
                className="img-wrapper"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <img src={step.icon} />
                {index !== steps.length - 1 && <Box className="dashed-line" />}
              </motion.div>
            </motion.div>

            <Typography variant="subtitle2"> Step {index + 1}</Typography>
            <Typography variant="body2">{step.label}</Typography>
          </StyledStepBox>
        ))}
      </StyledStep>
    </StyledStepBoxFrame>
  )
}

export default StepstoFollow
