import { Box, styled } from '@mui/material'
import { banner, newauBanner } from 'assets/images'

export const StyledBanner = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'relative',
  backgroundImage: `url(${newauBanner}) `,
  backgroundSize: 'cover',
  [theme.breakpoints.down('md')]: {
    height: 'auto',
  },
}))

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  zIndex: 2,
  color: 'white',
  fontSize: '40px',
  display: 'grid',
  placeItems: 'center',
  flexGrow: 1,
  height: '100%',
}))

export const StyledBannerContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 32px',
  '& img': {
    marginBottom: '40px',
  },
  '& .content-items': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
  },

  '& .MuiTypography-h1': {
    fontSize: '40px',
    fontWeight: 'bold',
    color: 'white',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: '20px',
    '& img': {
      width: '180px',
      marginBottom: '16px',
    },
    '& .MuiTypography-h1': {
      fontSize: '24px',
    },
    '& .content-items': {
      gap: '10px',
      marginBottom: '20px',
    },
  },
}))

export const StyledBannerMask = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  // background: 'linear-gradient(180deg, #2F1F64 0%, #91322B 100%)',
  zIndex: 1,
  opacity: 0.8,
}))

export const StyledAuthCards = styled(Box)(({ theme }) => ({
  zIndex: 2,
  width: '500px',
  height: 'min-content',
  background: 'white',
  borderRadius: '5px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))

export const StyledContainer = styled(Box)(({ theme }) => ({
  margin: 'auto',
  maxWidth: '1100px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '10px',
  },
}))

export const StyledMobileAuthCards = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}))

export const StyledBannerImage = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '890px',
  display: 'block',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '30vh',
  },
}))

export const StyledStep = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  margin: '30px 0',
  position: 'relative',
}))

export const StyledPoints = styled(Box)(({ theme }) => ({
  minWidth: '8px',
  height: '8px',
  backgroundColor: '#c12a37',
  borderRadius: '50%',
}))

export const StyledPointBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
}))

export const StyledStepBoxFrame = styled(Box)(({ theme }) => ({
  border: theme.borders.grayLight,
  margin: '100px 0',
  borderRadius: '10px',
  padding: '20px',
}))

export const StyledStepBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  alignItems: 'center',
  '& .img-wrapper': {
    width: '100px',
    height: '100px',
    borderRadius: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '30px',
    position: 'relative',
    margin: '10px',
    background:
      'linear-gradient(126deg, rgba(25,51,90,1) 42%, rgba(105,122,152,1) 100%)',
  },
  '& .dashed-line': {
    position: 'absolute',
    width: '140px',
    height: '3px',
    top: 50,
    left: 100,
    zIndex: -2,
    borderTop: '2px dashed #30486C',
  },
  '& .MuiTypography-root': {
    textAlign: 'center',
  },

  [theme.breakpoints.down('md')]: {
    '& .dashed-line': {
      display: 'none',
    },
  },
}))

export const StyledInstructions = styled(Box)(({ theme }) => ({
  width: '60%',
  height: 'auto',

  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: 'auto',
    gap: '0px',
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    padding: '10px',
    gap: '10px',
  },
}))

export const StyledApplication = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  margin: 'auto',
  display: 'flex',
  gap: '120px',
  marginBottom: '20px',
  marginTop: '20px',
  justifyContent: 'space-between',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    height: 'auto',
    padding: '10px',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    width: '100%',
    height: 'auto',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '10px',
  },
}))

export const StyledKeyPoints = styled(Box)(({ theme }) => ({}))

export const StyledEligibility = styled(Box)(({ theme }) => ({
  width: '100%',
  margin: 'auto',
  padding: '10px 10px 30px 10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}))
