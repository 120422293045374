import { FormSingleSelect, FormTextField } from '@campxdev/shared'
import { Box, Stack, Typography } from '@mui/material'

const casteData = [
  { label: 'BC-A', value: 'BC-A' },
  { label: 'BC-B', value: 'BC-B' },
  { label: 'BC-C', value: 'BC-C' },
  { label: 'BC-D', value: 'BC-D' },
  { label: 'BC-E', value: 'BC-E' },
  { label: 'OC', value: 'OC' },
  { label: 'SC', value: 'SC' },
  { label: 'ST', value: 'ST' },
]

export default function Step2Form({ control, setValue, clearErrors }) {
  return (
    <Stack gap={2}>
      <FormTextField
        control={control}
        name="interQualifyingBoard"
        label="Inter/10+2 Qualifying Board"
        required
      />
      <FormTextField
        control={control}
        name="interHallTicket"
        label="Inter/10+2 Hallticket"
        required
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <FormTextField
          control={control}
          name="interPercentage"
          label="Inter/10+2 Percentage"
          type="number"
        />
        <Typography variant="subtitle2" marginTop={'30px'}>
          Or
        </Typography>
        <FormTextField
          control={control}
          name="interCgpa"
          label="Inter/10+2 CGPA"
          type="number"
        />
      </Box>

      <FormTextField
        control={control}
        name="tenthQualifyingBoard"
        label="Tenth Qualifying Board"
        required
      />
      <FormTextField
        control={control}
        name="tenthHallTicket"
        label="Tenth Hallticket"
        required
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <FormTextField
          control={control}
          name="tenthPercentage"
          label="Tenth Percentage"
          type="number"
        />
        <Typography variant="subtitle2" marginTop={'30px'}>
          Or
        </Typography>
        <FormTextField
          control={control}
          name="tenthCgpa"
          label="Tenth CGPA"
          type="number"
        />
      </Box>
      <FormTextField
        control={control}
        name="tenthTotalMarks"
        label="Tenth total marks"
        type="number"
        required
      />

      <FormSingleSelect
        label={'Caste'}
        name={'caste'}
        control={control}
        options={casteData}
        required
      />
      <FormTextField
        control={control}
        name="aadharNumber"
        label="Aadhar Number"
        required
      />

      <FormTextField
        control={control}
        name="nationality"
        label="Nationality"
        required
      />
      <FormTextField
        control={control}
        name="religion"
        label="Religion"
        required
      />
    </Stack>
  )
}
