import { Box, Button, Typography } from '@mui/material'
import { tickGif } from 'assets/images'
import React from 'react'

function Thankyou() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={tickGif} width={'200px'} />
      <Typography variant="h6">Thank You for submitting the form</Typography>
      <Typography variant="h6">
        We will get back to you as soon as possible
      </Typography>

      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        onClick={() => window.location.replace('/')}
      >
        Home
      </Button>
    </Box>
  )
}

export default Thankyou
