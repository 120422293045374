import { FormTextField, axiosErrorToast } from '@campxdev/shared'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import Cookies from 'js-cookie'
import {
  Logo,
  StyledAuthButtons,
  StyledOutlinedButton,
} from 'pages/AuthPages/EmailLogin'
import { StyledFormSection } from 'pages/AuthPages/styles'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import axios from 'utils/axios'

function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, control } = useForm()
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const onSubmit = async (values) => {
    try {
      const res = axios
        .post(`/auth-server/auth/lead-login`, values)
        .then((res) => {
          Cookies.set('campx_lead_tenant', res?.data?.subDomain)
          Cookies.set('campx_lead_key', res?.data?.access_token)
          navigate(`/my-profile/${res?.data?.leadId}`)
        })
        .catch((err) => {
          axiosErrorToast(err)
        })
    } catch (err) {
      setError(err.response.data.message ?? 'Server Error')
    }
  }

  return (
    <>
      <Box width={'100%'} marginTop="20px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap={'20px'}>
            <FormTextField
              size="small"
              control={control}
              name="username"
              label="User Email"
              required
            />
            <FormTextField
              control={control}
              name="password"
              label="Password"
              size="small"
              type={showPassword ? 'text' : 'password'}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledAuthButtons type="submit" fullWidth>
              Login
            </StyledAuthButtons>
          </Stack>
        </form>
        {error && (
          <Alert severity="error" sx={{ marginTop: '20px' }}>
            {error}
          </Alert>
        )}
      </Box>
    </>
  )
}

export default Login
