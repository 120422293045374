import {Typography} from '@mui/material'

export default function FormLabel({label, required}) {
	return (
		<>
			{label}
			{required && (
				<Typography fontSize={14} component={'span'} color='error'>
					{' *'}
				</Typography>
			)}
		</>
	)
}
