import { Box, Typography } from '@mui/material'
import { instructionImg, stepFill } from 'assets/images'
import React from 'react'
import { StyledInstructions, StyledPointBox, StyledPoints } from './styles'
import { motion } from 'framer-motion'

const instructions = [
  {
    text: 'The online application is for admission to programmes offered in Anurag University.',
  },
  { text: 'Application Form Fee is Non-Refundable.' },
  {
    text: 'Email ID submitted at the time of registration will be used for all correspondences until enrolment is completed. Change in Email ID will NOT be permitted under any circumstances.',
  },
  {
    heading: 'Anurag University Query Management System:',
    text: 'Applicants are strongly advised to use Anurag University Query Management System (Anurag University-QMS), rather than emailing, to get a quick response.',
  },
  {
    text: 'Register and verify your email ID',
  },
  // {
  //   text: 'Click on [Any Queries? Ask US]in your dashboard',
  // },
  // {
  //   text: 'Select query category and submit your query',
  // },
]

function Instructions() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.8 }}
      transition={{ duration: 0.5 }}
    >
      <StyledInstructions>
        <Typography variant="h6" sx={{ fontSize: '40px' }}>
          Instructions
        </Typography>
        {instructions.map((instruction) => (
          <>
            <Typography
              sx={{ fontSize: '16px', margin: '10px 0px' }}
              variant="h6"
            >
              {instruction.heading}
            </Typography>
            <StyledPointBox>
              <StyledPoints />
              <Typography sx={{ fontSize: '15px' }}>
                {instruction.text}
              </Typography>
            </StyledPointBox>
          </>
        ))}
      </StyledInstructions>
    </motion.div>
  )
}

export default Instructions
