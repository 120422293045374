import { Store } from 'pullstate'

const leadStore = new Store({
  leadData: null,
  leadTasks: null,
  leadActivity: null,
  logo: null,
  logoSquare: null,
  clientName: null,
  branchDetails: null,
  notificationId: null,
  batch: null,
})

const initialState = () => {
  leadStore.update((s) => {
    s.leadData = null
    s.leadTasks = null
    s.leadActivity = null
    s.logo = null
    s.logoSquare = null
    s.clientName = null
    s.branchDetails = null
    s.notificationId = null
    s.batch = null
  })
}

export const LeadStore = {
  store: leadStore,
  initialState,
}
