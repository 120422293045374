import {
  ActionButton,
  Image,
  axiosErrorToast,
  useExternalScript,
} from '@campxdev/shared'
import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { formStore } from './AdmissionsForm'
import { paymentMethods } from './paymentMethods'
import {
  acknowledgePayment,
  initiateOrder,
  initiateRazorPayOrder,
  intiatePaymentOrder,
} from './services'
import { StyledPaymentCard } from './styles'
import axios from 'utils/axios'

const SelectPaymentMethod = ({ control, watch }) => {
  const { payment, leadDetails, rzyPayKey, type, rzrPayRes, clientLogo } =
    formStore.useState((s) => s)
  const navigate = useNavigate()
  const [disabled, setDisabled] = useState(true)

  let { id } = useParams()

  const url = `https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants/${rzyPayKey}.js`

  useExternalScript(url)

  const paytmBody = {
    amount: 500,
    channel: 'WEB',
    paymentMethod: payment.paymentMethod,
    clientNotes: {
      email: leadDetails.email,
      amount: 500,
      paymentMethod: payment.paymentMethod,
      mobilePayment: true,
      name: leadDetails.name,
      mobile: leadDetails.mobile,
      leadId: leadDetails.id,
      courseId: leadDetails.courseId,
    },
    notes: {
      email: leadDetails.email,
      amount: 500,
      name: leadDetails.name,
      mobile: leadDetails.mobile,
      paymentMethod: payment.paymentMethod,
      mobilePayment: true,
      leadId: leadDetails.id,
      courseId: leadDetails.courseId,
    },
  }

  const handleInitiatePayment = async () => {
    const initaiteResponse = await initiateOrder(paytmBody)

    formStore.update((s) => {
      s.rzrPayRes = initaiteResponse?.paymentDetails
      s.clientLogo = initaiteResponse?.assets?.logo
      s.rzyPayKey =
        initaiteResponse?.type == 'PAYTM'
          ? initaiteResponse?.merchantId
          : initaiteResponse?.razorpayKey
      s.type = initaiteResponse?.type
    })
  }

  const validateFormData = async () => {
    await axios
      .get('/square/leads/validate-lead-form-details')
      .then((res) => {
        handleInitiatePayment()
        setDisabled(false)
      })
      .catch((err) => {
        setDisabled(true)
        axiosErrorToast(err)
      })
  }

  useEffect(() => {
    validateFormData()
  }, [])

  const handleSubmit = async () => {
    const paytmOptions: any = {
      amount: 500,
      currency: 'INR',
      orderId: rzrPayRes?.razorpayOrderId,
      description: `Admission Fee Payment`,
      email: leadDetails?.email,
      fullName: leadDetails?.fullName,
      method: payment.paymentMethod,
      mobile: leadDetails?.mobile,
      cardType: paymentMethods?.find(
        (item) => item.value == payment.paymentMethod,
      )?.cardType,
      clientLogo,
      rzyPayKey,
      txnToken: rzrPayRes?.order?.orderResponse?.txnToken,
    }
    const razorpayOptions: any = {
      amount: rzrPayRes?.order?.orderResponse?.razorPayResponse?.amount,
      currency: 'INR',
      orderId: rzrPayRes?.order?.orderResponse?.orderId,
      description: `Admission Fee Payment`,
      email: leadDetails?.email,
      fullName: leadDetails?.name,
      method: payment.paymentMethod,
      mobile: leadDetails?.mobile,
      cardType: paymentMethods?.find(
        (item) => item.value == payment.paymentMethod,
      )?.cardType,
      clientLogo,
      rzyPayKey,
    }

    const onSuccess = (razorPayResponse) => {
      acknowledgePayment({
        razorPayResponse,
        type,
        paymentMethod: payment.paymentMethod,
        leadDetails,
        id,
      })
    }

    if (type == 'PAYTM') {
      await intiatePaymentOrder(paytmOptions, onSuccess)
    } else {
      await initiateRazorPayOrder(razorpayOptions, onSuccess)
    }
  }

  return (
    <>
      <Typography align="center" variant="body2" marginBottom={'20px'}>
        Complete Payment to Submit Application
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '1rem',
        }}
      >
        {paymentMethods?.map((item, index) => (
          <StyledPaymentCard
            key={index}
            onClick={() => {
              formStore.update((s) => {
                s.payment.paymentMethod = item.value
              })
            }}
            isActive={item?.value == payment.paymentMethod}
          >
            <Image alt="" src={item?.icon} height={36} width={36} />
            <Typography variant="body2">{item?.label}</Typography>
          </StyledPaymentCard>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <ActionButton onClick={handleSubmit} disabled={disabled}>
          Pay & Submit Application
        </ActionButton>
      </Box>
    </>
  )
}
export default SelectPaymentMethod
