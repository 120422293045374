import { Box } from '@mui/material'

const Image = ({
  src,
  height,
  width,
  objectFit = 'contain',
}: {
  src: string
  height: string
  width: string
  objectFit?: any
}) => {
  return (
    <Box
      sx={{
        height,
        width,
      }}
    >
      <img
        style={{
          width: '100%',
          height: '100%',
          objectFit,
        }}
        alt="Campx Logo"
        src={src}
      />
    </Box>
  )
}
export default Image
