const welcomeImage = require('./welcomeimage.png')
const campxLogoPrimary = require('./anuragcolor.png')
const campxLogoWhite = require('./campx_logo_white.png')
const WALogo = require('./walogo.png')
const AnuragCollege = require('./anurag-university-hyderabad-204464.jpg')
const avatarImage = require('./avatar.png')
const stepVerify = require('./verify.png')
const stepSubmit = require('./payment.png')
const stepRegister = require('./g15.png')
const stepLogin = require('./g4449.png')
const stepNotification = require('./g507.png')
const stepFill = require('./application3.png')
const instructionImg = require('./instructions.jpg')
const anurag = require('./anurag.png')
const banner = require('./banner_back.jpg')
const newauBanner = require('./anurageBanner.jpg')
import registerIcon from './register.svg'
import loginIcon from './login.svg'
import admissionIcon from './admission.svg'
import applicationIcon from './application.svg'
import submitIcon from './submit.svg'
import tickGif from './tick.gif'
import exam from './exams.png'
export {
  welcomeImage,
  campxLogoPrimary,
  campxLogoWhite,
  WALogo,
  AnuragCollege,
  avatarImage,
  stepVerify,
  stepSubmit,
  stepRegister,
  stepFill,
  instructionImg,
  anurag,
  stepLogin,
  stepNotification,
  banner,
  registerIcon,
  loginIcon,
  admissionIcon,
  applicationIcon,
  submitIcon,
  tickGif,
  newauBanner,
  exam,
}
