import { Box, Typography } from '@mui/material'
import {
  StyledApplication,
  StyledKeyPoints,
  StyledPointBox,
  StyledPoints,
} from './styles'
import { motion } from 'framer-motion'

const keyPoints = [
  {
    point:
      'Anurag University online application form is for admission into different Undergraduate, Postgraduate and Doctoral programs of Engineering, Pharmacy, Agriculture, Science, Management and Liberal arts.',
  },
  // {
  //   point: 'Anurag University application : Fee Rs. 500/-',
  // },
  {
    point: ' NRI/PIO/OCI candidates are also eligible to apply.',
  },
  {
    point:
      'Your choice of program preference is for statistical purpose only. Final decision of your choice of program would be finalized at the time of admission.',
  },
  {
    point:
      'Entrance Exam, Counseling and course commencement dates will be announced by the university from time to time.',
  },
  {
    point:
      'Admission to the courses offered by Anurag University is not guaranteed by submitting an application or paying the application fee. The Admissions Committee has the exclusive authority to grant admission based on merit and a personal interview with the applicant.',
  },
]

const programs = [
  {
    name: 'B.Tech. / B.Tech. (Hons)',
  },
  {
    name: 'M.Tech',
  },
  {
    name: 'B.Sc. (Hons) Agriculture',
  },
  {
    name: 'B.Pharmacy',
  },
  {
    name: 'Pharm.D',
  },
  {
    name: 'Pharm.D (PB)',
  },
  {
    name: 'M.Pharm',
  },
  {
    name: 'BBA',
  },
  {
    name: 'MBA',
  },
  {
    name: 'MBA (Business Analytics)',
  },

  {
    name: 'Ph.D Programs',
  },
]

function ApplicationForm() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }}
    >
      <StyledApplication>
        <StyledKeyPoints>
          <Typography variant="h6" sx={{ fontSize: '32px' }}>
            Key Points
          </Typography>
          {keyPoints.map((point, index) => (
            <StyledPointBox key={index}>
              <StyledPoints />
              <Typography sx={{ fontSize: '15px' }}>{point.point}</Typography>
            </StyledPointBox>
          ))}
        </StyledKeyPoints>
        <Box sx={{ minWidth: '300px' }}>
          <Typography variant="h6" sx={{ fontSize: '32px' }}>
            Programs Offered
          </Typography>
          {programs.map((program, index) => (
            <StyledPointBox key={index}>
              <Typography>{index + 1 + '. '}</Typography>
              <Typography sx={{ fontSize: '15px' }}>{program.name}</Typography>
            </StyledPointBox>
          ))}
        </Box>
      </StyledApplication>
    </motion.div>
  )
}

export default ApplicationForm
