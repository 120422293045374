import { Box, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { StyledEligibility, StyledPointBox, StyledPoints } from './styles'

const criteria = [
  {
    name: 'For Undergraduate Courses:',
    points: [
      'Candidates must have passed their 10+2 (or) equivalent examination in relevant subjects.',
      'Candidates must have scored at least 60% marks in the qualifying examination at 10+2 / Intermediate level and admission through the TSEAMCET/ AnuragCET conducted by the University/ JEE on Merit / Qualifying Exam on merit.',
    ],
  },
  {
    name: 'For Post Graduate Courses:',
    points: [
      'Candidates must have passed 4 years duration Bachelor’s Degree from any recognized Institute or University for Engineering or Pharmacy Streams.',
      'Candidates must have passed 3 years duration Bachelor’s Degree from any recognized Institute or University for any discipline to management.',
      'Candidates must have scored at least 60% marks in the qualifying examination at under graduation level and admission through GATE/ State Common Entrance/ AnuragCET conducted by the University/Qualifying Exam on merit.',
    ],
  },
  {
    name: 'PHD:',
    points: [
      'Candidates must have passed Masters Program or equivalent qualification in the relevant field.',
      'Admission through NET/GATE/ Equivalent National Level Common Entrance Test/State Common Entrance Test (if any)/ Anurag University Entrance Examination/ Merit & Interview conducted by the University.',
    ],
  },
]

function Eligibility() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true, amount: 0.2 }}
      transition={{ duration: 0.5 }}
    >
      <StyledEligibility>
        <Typography variant="h6" sx={{ fontSize: '40px' }}>
          Eligibility Criteria
        </Typography>
        <Typography sx={{ fontSize: '15px' }}>
          The eligibility criteria for programs offered at Anurag University
          (Engineering/ Pharmacy/ Agricultural Sciences/ Allied Health Sciences/
          Management & Commerce/ Sciences/ Arts) is as listed below:
        </Typography>
        {criteria.map((item, index) => (
          <Box key={index}>
            <Typography variant="h6">{item.name}</Typography>
            {item.points.map((point, index) => (
              <StyledPointBox key={index}>
                <StyledPoints />
                <Typography sx={{ fontSize: '15px' }}>{point}</Typography>
              </StyledPointBox>
            ))}
          </Box>
        ))}
      </StyledEligibility>
    </motion.div>
  )
}

export default Eligibility
