import { NetworkStore } from '@campxdev/shared/src/components/ErrorBoundary/GlobalNetworkLoadingIndicator'
import Axios from 'axios'
import { tenantName } from 'constants/UIConstants'
import Cookies from 'js-cookie'
import { toast } from 'react-toastify'

const sessionKey = Cookies.get('campx_lead_key')
const sessionId = Cookies.get('campx_lead_tenant')

const collegeId = window.location.origin.split('.').slice(-3, -1)[0]
export const formatParams = (params) => {
  return Object.fromEntries(
    Object.entries(params ?? {})?.map((i) => [
      i[0],
      i[1] === '__empty__' ? '' : i[1],
    ]),
  )
}

let axios = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
  headers: {
    'x-tenant-id': tenantName[collegeId] ?? 'aupulse',
    ...(sessionKey && {
      campx_lead_key: sessionKey,
      campx_lead_tenant: sessionId,
    }),
    'x-institution-code': 'aupulse',
  },
})

axios.interceptors.request.use(
  function (config) {
    const params = formatParams(config?.params)
    NetworkStore.update((s) => {
      s.loading = true
    })
    return { ...config, params }
  },
  function (error) {
    NetworkStore.update((s) => {
      s.loading = false
    })
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    NetworkStore.update((s) => {
      s.loading = false
    })
    return response
  },
  function (err) {
    NetworkStore.update((s) => {
      s.loading = false
    })
    return Promise.reject(err)
  },
)

export default axios

export const axiosErrorToast = (error: any, fallback?: string) => {
  const fallbackMessage = fallback ?? 'Something went wrong.'
  const errorMessage =
    typeof error?.response?.data?.message !== 'string'
      ? error?.response?.data?.message?.join('\n') ?? fallbackMessage
      : error?.response?.data?.message

  return toast.error(errorMessage)
}
