import { paymentMethods } from 'pages/AdmissionsForm/paymentMethods'

const paymentModes = ['CC', 'DC', 'NB', 'UPI', 'PPBL', 'PPI', 'BALANCE']

export const paytmModes = {
  netbanking: 'NB',
  credit_card: 'CC',
  debit_card: 'DC',
  upi: 'UPI',
}

export const razorPayConfig = (payload, onSuccess) => {
  let razorpay
  try {
    const razorPayOptions = {
      key: payload?.rzyPayKey,
      amount: payload?.amount,
      currency: payload?.currency,
      order_id: payload?.orderId,
      description: 'Admission Form Fee',
      image: payload.clientLogo,
      email: payload?.email || '',
      contact: payload?.mobile || '',
      type:
        paymentMethods?.find((item) => item.value == payload.method).cardType ??
        undefined,
      prefill: {
        name: payload?.fullName || '',
        email: payload?.email || '',
        contact: payload?.mobile || '',
      },
      method: {
        netbanking: payload.method === 'netbanking',
        card:
          payload.method === 'debit_card' || payload.method === 'credit_card',
        wallet: payload.method === 'wallet',
        upi: payload.method === 'upi',
      },
      handler: async function (response) {
        if (response) onSuccess(response)
      },
    }
    razorpay = new (window as any).Razorpay(razorPayOptions)
    razorpay.open()
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const paytmConfig = (payload, onSuccess) => {
  const filteredModes = paymentModes.filter(
    (mode) => mode !== paytmModes[payload.method],
  )

  const paytmWindow = window as any
  var config = {
    root: '',
    style: {
      bodyBackgroundColor: '#fafafb',
      bodyColor: '',
      themeBackgroundColor: '#0FB8C9',
      themeColor: '#ffffff',
      headerBackgroundColor: '#284055',
      headerColor: '#ffffff',
      errorColor: '',
      successColor: '',
      card: {
        padding: '',
        backgroundColor: '',
      },
    },
    data: {
      orderId: payload?.orderId,
      token: payload?.txnToken,
      tokenType: 'TXN_TOKEN',
      amount: payload?.amount /* update amount */,
    },
    payMode: {
      labels: {},
      filter: {
        exclude: filteredModes,
      },
    },
    website: 'DEFAULT',
    flow: 'DEFAULT',
    merchant: {
      mid: payload.rzyPayKey,
      redirect: false,
    },

    handler: {
      transactionStatus: function transactionStatus(paymentStatus) {
        if (paymentStatus?.STATUS == 'TXN_SUCCESS') {
          paytmWindow.Paytm.CheckoutJS.close()
          onSuccess(paymentStatus)
        }
      },
      notifyMerchant: function notifyMerchant(eventName, data) {
        console.log('Closed =>', eventName, data)
      },
    },
  }

  if (paytmWindow.Paytm && paytmWindow.Paytm.CheckoutJS) {
    paytmWindow.Paytm.CheckoutJS.init(config)
      .then(function onSuccess() {
        paytmWindow.Paytm.CheckoutJS.invoke()
      })
      .catch(function onError(error) {
        console.log('Error => ', error)
      })
  }
}
