import { ActionButton, Spinner, axiosErrorToast } from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, LinearProgress, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Store } from 'pullstate'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { LeadStore } from 'store/LeadStore'
import axios from 'utils/axios'
import Step1Form from './Step1Form'
import Step2Form from './Step2Form'
import Step3Form from './Step3Form'
import Step4Form from './Step4Form'
import { paymentMethods } from './paymentMethods'
import { updateLeadDetails } from './services'
import {
  StyledFooter,
  StyledFormContainer,
  StyledHeading,
  StyledLogoContainer,
} from './styles'
import { fetchDynamicSchema } from './validation'

export const formStore = new Store({
  step: 1,
  leadDetails: null,
  payment: {
    paymentMethod: paymentMethods[0].value,
  },
  rzrPayRes: null,
  clientLogo: null,
  rzyPayKey: null,
  type: null,
  formErrors: null,
})

function FormFooter({ onSubmit, posting }) {
  const { step, formErrors } = formStore.useState((s) => s)

  return (
    <>
      <StyledFooter>
        <Box className="content">
          <LinearProgress
            variant="determinate"
            value={step * 25}
            sx={{ height: '8px', borderRadius: '5px' }}
          />
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
              padding: '10px 0',
            }}
          >
            {step === 1 ? (
              <Box width="100%"></Box>
            ) : (
              <ActionButton
                fullWidth
                variant="outlined"
                onClick={() => {
                  if (step === 1) return
                  formStore.update((s) => {
                    s.step = s.step - 1
                  })
                }}
              >
                Back
              </ActionButton>
            )}

            {step !== 4 && (
              <ActionButton
                loading={posting}
                fullWidth
                onClick={async () => {
                  onSubmit()
                }}
              >
                Save and continue
              </ActionButton>
            )}
          </Box>
        </Box>
      </StyledFooter>
    </>
  )
}

function AdmissionsForm() {
  let { id } = useParams()
  const queryClient = useQueryClient()
  const [search] = useSearchParams()
  const leadState = LeadStore.store.useState()
  const { step } = formStore.useState()

  const { handleSubmit, control, watch, setValue, clearErrors } = useForm({
    resolver: yupResolver(fetchDynamicSchema(step)),
  })

  const { isLoading: loadingLeadDetails } = useQuery(
    'lead-details',
    () => axios.get('/square/leads/details').then((res) => res.data),
    {
      onSuccess(data) {
        Object.entries(data).forEach(([key, value]: [string, any]) => {
          setValue(key, value)
          if (key === 'aadharNumber' && value) setValue('aadharNumber', value)
          if (key === 'interHallTicket' && value)
            setValue('interHallTicket', value)
          if (key === 'tenthHallTicket' && value)
            setValue('tenthHallTicket', value)
        })
        formStore.update((s) => {
          s.leadDetails = data
          s.formErrors = null
        })
      },
      onError(error: any) {
        formStore.update((s) => {
          s.step = 3
        })
      },
    },
  )

  const { mutate, isLoading: posting } = useMutation(updateLeadDetails, {
    onSuccess() {
      queryClient.invalidateQueries('lead-details')
      if (step == 3) {
        formStore.update((s) => {
          s.step = 4
        })
      }
    },
    onError(error: any) {
      axiosErrorToast(error, 'Unable to save details')
    },
  })

  async function onSubmit(formData) {
    if (step <= 3) {
      formStore.update((s) => {
        s.step = s.step + 1
      })

      mutate({
        body: {
          ...formData,
          batch: search.get('batch'),
          admissionNotificationId: id,
          dob: format(new Date(formData?.dob), 'yyyy-MM-dd'),
          interPercentage: +formData.interPercentage,
          interSecuredMarks: +formData.interSecuredMarks,
          tenthPercentage: +formData.tenthPercentage,
          fatherName: formData?.fatherName ?? null,
          motherName: formData?.motherName ?? null,
          step: step,
        },
      })
    }
  }
  const submitForm = handleSubmit(onSubmit, (err) => {
    // eslint-disable-next-line no-console
    console.log(err)
  })

  if (loadingLeadDetails) return <Spinner />
  return (
    <StyledFormContainer>
      <StyledLogoContainer>
        <img src={leadState.logo} width={180} />
        <Typography variant="h6">
          {leadState.clientName} Admission Form
        </Typography>
      </StyledLogoContainer>
      <Box sx={{ paddingBottom: '80px' }}>
        <StyledHeading variant="h1">{`Step ${step}`}</StyledHeading>
        {step === 1 && <Step1Form control={control} />}
        {step === 2 && (
          <Step2Form
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
          />
        )}
        {step === 3 && <Step3Form control={control} watch={watch} />}
        {step === 4 && <Step4Form control={control} watch={watch} />}
      </Box>
      <FormFooter onSubmit={submitForm} posting={posting} />
    </StyledFormContainer>
  )
}

export default AdmissionsForm
