const netbankingIcon = require('./assets/netbanking.png')
const debitCardIcon = require('./assets/debit_card.png')
const upiIcon = require('./assets/upi.png')
const creditCardIcon = require('./assets/credit_card.png')

export const paymentMethods = [
  {
    label: 'Net Banking',
    value: 'netbanking',
    cardType: undefined,
    icon: netbankingIcon,
  },
  {
    label: 'Debit Card',
    value: 'debit_card',
    cardType: 'debit',
    icon: debitCardIcon,
  },
  {
    label: 'Credit Card',
    value: 'credit_card',
    cardType: 'credit',
    icon: creditCardIcon,
  },
  { label: 'UPI', value: 'upi', cardType: undefined, icon: upiIcon },
]
