import * as yup from 'yup'

const mobileRegx: any = new RegExp(/^[6-9]\d{9}$/)

const aadharRegex = new RegExp(
  '^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}s[0-9]{4}s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)',
)

const hallTicketRegex = new RegExp(/^\d+$/)

export const fetchDynamicSchema = (step) => {
  switch (step) {
    case 1:
      return schema1
    case 2:
      return schema2
    case 3:
      return schema3
    default:
      return null
  }
}
export const schema1 = yup.object().shape(
  {
    name: yup.string().required('Name is required'),
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    gender: yup.string().required('Gender is required').nullable(),
    dob: yup.string().required('Date of birth is required').nullable(),
    mobile: yup
      .string()
      .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
      .nullable()
      .required('Mobile number is required')
      .nullable(),
    fatherName: yup
      .string()
      .when('guardianName', function (v) {
        if (v == null)
          return this.required(
            'Either Parent name or Guardian name is required',
          )
      })
      .optional()
      .nullable(),
    motherName: yup
      .string()
      .when('guardianName', function (v) {
        if (v == null)
          return this.required(
            'Either Parent name or Guardian name is required',
          )
      })
      .optional()
      .nullable(),
    guardianName: yup
      .string()
      .when('fatherName', function (v) {
        if (v == null)
          return this.required(
            'Either Parent name or Guardian name is required',
          )
      })
      .when('motherName', function (v) {
        if (v == null)
          return this.required(
            'Either Parent name or Guardian name is required',
          )
      })
      .optional()
      .nullable(),
    guardianRelation: yup
      .string()
      .when('guardianName', {
        is: (value) => value?.length > 0,
        then: yup.string().required('This is required').nullable(),
        otherwise: yup.string().notRequired().nullable(),
      })

      .nullable(),
    parentOccupation: yup.string().optional().nullable(),
    permanentAddress: yup
      .string()
      .required('Permanent address is required')
      .nullable()
      .nullable(),
    parentMobile: yup
      .string()
      .matches(mobileRegx, 'Enter a valid 10 digit mobile number')
      .nullable()
      .required('Parent Mobile number is required')
      .nullable(),
  },
  [
    ['fatherName', 'guardianName'],
    ['motherName', 'guardianName'],
  ],
)

export const schema2 = yup.object().shape(
  {
    interHallTicket: yup
      .string()
      .matches(hallTicketRegex, 'Enter a valid HallTicket Number')
      .required('Inter Hall Ticket number is required')
      .nullable(),
    interPercentage: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('interCgpa', function (v) {
        if (v == null)
          return this.required('Either of cgpa or percentage is required')
      })
      .nullable(),
    interCgpa: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('interPercentage', function (v) {
        if (v == null)
          return this.required('Either of cgpa or percentage is required')
      })
      .nullable(),

    tenthPercentage: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('tenthCgpa', function (v) {
        if (v == null)
          return this.required('Either of cgpa or percentage is required')
      }),
    tenthCgpa: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .when('tenthPercentage', function (v) {
        if (v == null)
          return this.required('Either of cgpa or percentage is required')
      })
      .nullable(),
    tenthTotalMarks: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('This is required'),

    interQualifyingBoard: yup
      .string()
      .required('Inter/10+2 Qualifying Board is required')
      .nullable(),
    tenthHallTicket: yup
      .string()
      .matches(hallTicketRegex, 'Enter a valid HallTicket Number')
      .required('10th Hall Ticket number is required')
      .nullable(),
    tenthQualifyingBoard: yup
      .string()
      .required('Tenth Qualifying Board is required')
      .nullable(),
    aadharNumber: yup
      .string()
      .required('Aadhar number is required')
      .matches(aadharRegex, 'Enter a valid aadhar number')
      .nullable(),

    caste: yup.string().required('Caste is required').nullable(),
    nationality: yup.string().required('Nationality is required').nullable(),
    religion: yup.string().required('Religion is required').nullable(),
  },

  [
    ['interPercentage', 'interCgpa'],
    ['tenthPercentage', 'tenthCgpa'],
  ],
)
export const schema3 = yup.object().shape({
  courseId: yup.string().required('Course is required'),
  choice1: yup.string().required('Preference 1 is required'),
})
