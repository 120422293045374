import {
  ConfirmContextProvider,
  DialogProvider,
  GlobalNetworkLoadingIndicator,
  MuiThemeProvider,
  QueryClientProvider,
  ToastContainer,
} from '@campxdev/shared'
import LoginFormProvider from '@campxdev/shared/src/contexts/LoginFormProvider'
import Cookies from 'js-cookie'
import { ReactNode, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

export const campxTenantKey = Cookies.get('campx_tenant')
export const urlTenantKey = window.location.pathname.split('/')[1]

export default function Providers({
  children,
  showSuperAdminLoginForm = false,
}: {
  children: ReactNode
  showSuperAdminLoginForm?: boolean
}) {
  return (
    <BrowserRouter>
      <QueryClientProvider>
        <MuiThemeProvider>
          <ConfirmContextProvider>
            <DialogProvider>
              <LoginFormProvider>
                {children}
                <GlobalNetworkLoadingIndicator />
              </LoginFormProvider>
              <ToastContainer />
            </DialogProvider>
          </ConfirmContextProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
